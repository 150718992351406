.footer__container {
  /* background-color: #151515; */
  background-color: black;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 100px;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer_copywrite {
  color: gray;
  font-size: 12px;
  position: relative;
  /* top: 20px; */
  cursor: pointer;
}
.footer__social-icons {
  height: 40px;
  position: relative;
  top: 15px;
  cursor: pointer;
}
.footer__social-icons:hover {
  transform: scale(1.2);
  transition: 250ms all;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 900px) {
  .footer__social-icons {
    height: 30px;
    width: 30px;
  }
}
