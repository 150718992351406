.engagement-popup__container {
  background-color: black;
  color: white;
  height: 700px;
  width: 900px;
  position: relative;
  top: 7vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: -webkit-center;
}
.engagement-popup__opera{
  font-style: italic;
}
.engagement-popup__carousel {
  /* height: 200px; */
  width: 100%;
}
.review-section {
  padding: 20px;
}
.review-text {
  color: white;
  font-weight: 800;
  font-size: 10px;
}
.review-text-from {
  color: white;
  margin-top: 0px;
  font-size: 8px;
}
@media (max-width: 900px) {
  .engagement-popup__container {
    width: 95vw;
  }
  .modal_btn {
    position: relative;
    bottom: 25px;
  }
  .engagement-popup__carousel {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .engagement-popup__container {
    width: 95vw;
    height: 99vh;
    position: relative;
    top: 50px;
  }
  .modal_btn {
    position: relative;
    bottom: 25px;
  }
  .engagement-popup__carousel {
    width: 100%;
  }
}
