.nav__container {
  display: flex;
  justify-content: space-around;
  width: 50vw;
}
.nav__container p:hover {
  color: red;
  cursor: pointer;
}
.hamburger__menu {
  display: none;
}

.hamburger__menu__open {
  position: absolute;
  background-color: white;
  top: -20px;
  right: -50px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 14px 0 black;
}
.hamburger__menu__open p:hover {
  cursor: pointer;
  color: red;
}
.hamaburger-menu__x {
  color: red;
  position: relative;
  left: 100px;
  bottom: 20px;
  font-size: 30px;
}
.hamburger-menu__links {
  position: relative;
  bottom: 50px;
}
.hamburger-menu__links p {
  padding-bottom: 20px;
}

@media (max-width: 900px) {
  .nav__container {
    display: none;
  }
  .hamburger__menu {
    display: block;
    position: absolute;
    top: -20px;
    right: -10px;
  }
}
