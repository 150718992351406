.te-deum-test{
  background-color: red;
  z-index: 999;
}

.media__container {
  position: relative;
  bottom: 21px;
  min-height: 80vh;

  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #434343 74%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 50px;
}


.video__section {
  color: white;
  text-align: center;
  /* background-color: red; */
  /* display: flex; */
  /* width: 50vw; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  margin:20px;
  /* left: 50px; */
  /* background-color: red; */
  flex-wrap: wrap;
  width: 100%;
}
.media__video {
  margin: 30px;

}

@media (max-width: 1090px) {
 
}
@media (max-width: 590px) {
  .media__video {
    width: 320px;
  }
  .audio-video__split {
    width: 50vw;
  }
}
