.header__container {
  display: flex;
  justify-content: space-around;
  height: 100px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  transition-duration: 500ms;
  transition-property: height;
}
.header__container-smaller {
  display: flex;
  justify-content: space-around;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.header__logo__container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.header__logo-title {
  border-bottom: 1px solid black;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 30px;
  transition-duration: 500ms;
  transition-property: font-size;
}
.header__logo-title-smaller {
  position: relative;
  bottom: 10px;
  border-bottom: 1px solid black;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 20px;
  transition-duration: 500ms;
  transition-property: font-size;
}
.header__logo-fach {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: small;
  position: relative;
  bottom: 28px;
  left: 20px;
  letter-spacing: 18px;
  font-size: 10px;
  transition-duration: 100ms;
  transition-property: font-size;
}
.header__logo-fach-smaller {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: small;
  position: relative;
  bottom: 30px;
  left: 8px;
  letter-spacing: 12px;
  font-size: 10px;
  transition-duration: 1s;
  transition-property: font-size;
}
.navBar__holder {
  position: relative;
  top: 20px;
  z-index: 999;
  transition-duration: 1s;
  transition-property: top;
}
.navBar__holder-smaller {
  position: relative;
  top: 0px;
  z-index: 999;
  transition-duration: 1s;
  transition-property: top;
}
