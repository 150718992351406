.carousel {
  width: 100%;
}
.carousel__image {
  position: relative;
  bottom: 1px;
}

@media (max-width: 500px) {
  .carousel {
    height: 200px;
    width: auto;
  }
  .carousel__image {
    height: 200px;
    object-fit:contain;
  }
}
