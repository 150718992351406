.contact__container {
  min-height: 80vh;
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #434343 74%);
}

.contact__split {
  display: flex;
  justify-content: space-around;
}
.contact__copy {
  color: white;
  width: 300px;
  text-align: center;
  position: relative;
  top: 50px;
}
.contact__image-holder {
}
.contact__image {
  width: 500px;
  position: relative;
  top: 150px;
  /* border: solid black; */
  border-radius: 25%;
  box-shadow: 0;
  animation: mymove;
  /* animation-delay: 1s; */
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes mymove {
  from {
    box-shadow: 0;
  }
  to {
    box-shadow: 0 4px 14px 0 white;
  }
}

.Red {
  color: red;
}

@media (max-width: 900px) {
  .contact__split {
    flex-direction: column-reverse;
    text-align: center;
  }
  .contact__image {
    width: 320px;
    position: relative;
    top: 10px;
  }
  .contact__copy {
    align-self: center;
    margin-bottom: 150px;
  }
}
