.listen-button__container{
   border-top-right-radius: 5%;
  position: fixed;
  font-size: small;
  bottom: 20px;
  height: 100px;
  width: 80px;
  background-color: red;
  z-index: 999;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  border: 1px solid black
}
.listen-button__container:hover{
  border-bottom-right-radius: 5%;
  /* font-size: large; */
  position: fixed;
  bottom: 20px;
  height: 100px;
  width: 100px;
  background-color: red;
  z-index: 999;
  cursor: pointer;
}

@media (max-width: 800px){
  .listen-button__container{
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    position: fixed;
    font-size: small;
    bottom: 14%;
    height: 50px;
    width: 50px;
    background-color: red;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listen-button__container:hover{
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    position: fixed;
    font-size: small;
    bottom: 10%;
    height: 50px;
    width: 50px;
    background-color: red;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-text{
    display: none;
  }
}