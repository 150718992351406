.upcoming__container {
  color: white;
  text-align: center;
  font-family: monospace;
}

.upcoming__container p {
  font-size: 15px;
}

.upcoming__container img {
  height: 100px;
}

.bold {
  font-weight: 900;
}

.upcoming___show-container {
  padding: 20px;
  color: white;
}

.upcoming___show-container:hover {
  background-color: red;
  cursor: pointer;
}

.twentyTwo-shows {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 20px;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .twentyTwo-shows {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row on smaller screens */
  }
}

@media (max-width: 450px) {
  .upcoming__container img {
    height: auto;
    width: 100%;
  }

  .twentyTwo-shows {
    grid-template-columns: 1fr; /* 1 item per row on small screens */
    justify-content: center;
  }
}
