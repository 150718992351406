.gallery__container {
  min-height: 100vh;
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #434343 74%);
  position: relative;
  bottom: 20px;
  color: white;
  text-align: center;
}
.gallery__carousel-holder {
  margin-bottom: 50px;
  text-align: -webkit-center;
}
